import { ActivatedRoute, Router } from '@angular/router';
import { BatchDppQuiz, BatchQuizModel } from '../../services/batch/batch.modal';
import { last, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { TestSeriesService } from 'src/app/common/services/tests/test-series.service';
import { GlobalService } from 'src/app/common/services/global/global.service';

import {
  BatchDetailModel,
  HomeworkId,
  Subject,
} from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseAlertComponent } from 'src/app/common/components/purchase-alert/purchase-alert.component';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { TestList } from '../../../pages/tests/tests.model';
import { isAfter, isBefore } from 'date-fns';
import { COMING_SOON_IMG } from 'src/app/core/assets.location';
import { TestSeriesHelperService } from '../../services/tests/test-series-helper.service';
import { StorageService } from '../../services/storage/storage.service';
import { STUDY_PAGE_EVENT } from 'src/app/core/analytics-events';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics/firebase-analytics.service';
import { PracticeService } from '../../services/practice/practice.service';
import { RIGHT_BLUE_CIRCULAR_ARROW } from 'src/app/core/assets.location';
import { Learn2EarnService } from '../../services/learn-2-earn/learn-2-earn.service';
import { Learn2EarnConfigModel } from '../learn-2-earn/learn-2-earn.model';

@Component({
  selector: 'app-test-dpp-listing-common',
  templateUrl: './test-dpp-listing-common.component.html',
  styleUrls: ['./test-dpp-listing-common.component.scss'],
})
export class TestDppListingCommonComponent implements OnChanges {
  @Output('openContent') openContentEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Input() isSubjective: boolean;
  @Input() type: string;
  @Input() selectedType: string;
  @Input() fromBatch: boolean;
  @Input() title?: string = '';
  @Input() subjectId: string;
  @Input() tag: string;
  @Input() chapterId: string;

  //for test only
  testQuery: any;
  dppSubjectId: string;
  testPaginate: boolean;
  testProcessing: boolean;
  testList: TestList[] = [];
  getMoreDpps: boolean;
  getMoreBGDPP: boolean;
  exerciseQuery: any;
  exercisePaginate: boolean;
  exerciseProcessing: boolean;
  exerciseList: BatchQuizModel[] = [];
  quizContents: BatchDppQuiz[] = [];
  bgDppList: any[];
  requiredIds = {
    batchId: '',
    testCatId: '',
  };
  activatedRouteSubs: Subscription;
  processing: boolean;
  dppBGPage: number = 0;
  homeworkList: Array<HomeworkId> = [];
  comingSoonImg = COMING_SOON_IMG;
  isDppSelected: boolean = false;
  isTestSeriesSelected: boolean = false;
  // fromBatches: boolean;
  batchData: BatchDetailModel;
  batchDataSubs: Subscription;
  testListing: TestList[] = [];
  subjectName: string = '';
  testListObservable: Observable<TestList[]> = of([]);
  batchId: string;
  selected: string;
  query: any;
  cameFrom: string;
  batchScheduleId: any;
  batchSubjectId: string;
  tagId: string;
  userInfo: any;
  rightBlueArrow = RIGHT_BLUE_CIRCULAR_ARROW;
  learn2EarnConfig: Learn2EarnConfigModel;
  constructor(
    private batchService: BatchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private testService: TestSeriesService,
    private globalService: GlobalService,
    private dialog: MatDialog,
    private _testService: TestSeriesService,
    private testSeriesHelperService: TestSeriesHelperService,
    private storageService: StorageService,
    private firebaseService: FirebaseAnalyticsService,
    private practiceService: PracticeService,
    private learn2EarnService: Learn2EarnService
  ) {
    this.subjectName =
      this.activatedRoute.snapshot.queryParamMap.get('subject') || '';
    this.cameFrom =
      this.activatedRoute.snapshot.queryParamMap.get('came_from') || '';
    this.tagId =
      this.activatedRoute.snapshot.queryParamMap.get('chapterId') || '';
    this.userInfo = JSON.parse(this.storageService.getUser() || '{}');
  }

  ngOnInit() {
    this.checkLearn2EarnConfigData();
  }

  async checkLearn2EarnConfigData() {
    try {
      this.learn2EarnService.getConfigDataSubscribe().subscribe(
        async (res) => {
          if (res) {
            this.learn2EarnConfig = res;
            return;
          }
          const cohortId =
            this.storageService.getValue('COHORT_ID', 'string') || '';
          const localStorageData =
            this.learn2EarnService.checkLocalStorageConfig();
          let result;
          if (!localStorageData) {
            result = await lastValueFrom(
              this.learn2EarnService.getLearn2EarnConfig(cohortId)
            );
          } else {
            result = localStorageData;
          }
          this.learn2EarnConfig = result;
          this.learn2EarnService.setConfigData(this.learn2EarnConfig);
        },
        () => {
          console.log('Unable to get user menu value');
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  joinBattle(dppData: any) {
    if (!this.batchData?.isPurchased && !dppData?.isSharingEnabled) {
      this.globalService.showSnackBar('Batch not purchased');
      return;
    }
    if (
      dppData?.studentSideProgressStatus === 'ENDED' &&
      dppData?.studentBattleStatus === 'NOT_STARTED'
    ) {
      return;
    }
    if (dppData?.studentSideProgressStatus === 'ENDED') {
      this.router.navigate(
        [`tests/battle-ground-result-analyzing-screen/${dppData?._id}`],
        {
          queryParams: {
            cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
            cameFromScreen: 'documents',
          },
        }
      );
      return;
    }
    if (dppData?.studentBattleStatus === 'COMPLETED') {
      this.router.navigate(
        [`tests/battle-ground-result-analyzing-screen/${dppData?._id}`],
        {
          queryParams: {
            cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
            cameFromScreen: 'documents',
          },
        }
      );
      return;
    }
    if (dppData?.studentSideProgressStatus === 'UPCOMING') {
      this.router.navigate(
        [`/tests/battle-ground-test-engine/${dppData?._id}`],
        {
          queryParams: {
            isUpcoming: true,
            cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
            cameFromScreen: 'documents',
          },
        }
      );
      return;
    }
    if (dppData?.studentSideProgressStatus === 'LIVE') {
      this.router.navigate(
        [`/tests/battle-ground-test-engine/${dppData?._id}`],
        {
          queryParams: {
            type: 'dppBattle',
            isResume: dppData?.studentBattleStatus === 'PAUSED' ? 'true' : '',
            cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
            cameFromScreen: 'documents',
          },
        }
      );
      return;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['fromBatch']['currentValue'] === true) {
      this.batchData = this.batchService.getBatchData();
      this.batchId = this.batchData.slug;
      this.batchSubjectId =
        this.batchData.subjects.find((res) => res.slug === this.subjectId)
          ?._id || '';
      this.dppSubjectId =
        this.batchData.subjects.find((res) => res.slug === this.subjectId)
          ?.subjectId || '';
    }
    if (changes['type']['currentValue'] === 'test-series') {
      this.testQuery = {
        page: 1,
        catIds: this.batchData?.testCat || this.requiredIds.testCatId || '',
      };
      this.isTestSeriesSelected = true;
      this.testQuery['isSubjective'] = this.isSubjective;
      this.getTestCategories();
    }
    if (changes['type']['currentValue'] === 'dpp') {
      this.query = {
        page: 1,
        limit: 50,
        batchId: this.batchData?._id,
        batchSubjectId: this.batchSubjectId,
        isSubjective: this.isSubjective,
      };

      if (this.tag !== 'all') {
        this.query['chapterId'] = this.chapterId ? this.chapterId : this.tagId;
      }
      this.getContents(this.query);
      if (this.tag === 'all') {
        this.getBGDppList();
      }
    }
  }

  async loadMore(event: boolean) {
    if (this.getMoreDpps) {
      this.query.page++;
      await this.getContents(this.query);
    }
  }
  trackByMethod(i: number, el: BatchDppQuiz): string {
    return el.test._id;
  }

  getUtcStartEndTime = () => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const utcStartDate = startDate.toISOString();
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 0);
    const utcEndDate = endDate.toISOString();
    if (utcStartDate && utcEndDate) {
      return { utcStartDate, utcEndDate };
    }
    return { utcStartDate: '', utcEndDate: '' };
  };

  async getBGDppList(loadMore: boolean = false) {
    const LIMIT = 10;
    if (!loadMore) {
      this.processing = true;
    }
    const res: any = await lastValueFrom(
      await this.batchService.getBGDppList(
        this.batchData?._id,
        this.getUtcStartEndTime()?.utcStartDate,
        this.getUtcStartEndTime()?.utcEndDate,
        this.dppSubjectId,
        ++this.dppBGPage,
        LIMIT
      )
    );
    if (this.bgDppList?.length === res?.paginate?.totalCount) {
      this.getMoreBGDPP = false;
    } else {
      this.getMoreBGDPP = true;
    }
    if (!loadMore) {
      this.processing = false;
    }
    if (loadMore) {
      this.bgDppList = [...this.bgDppList, ...res?.data];
      return;
    }
    this.bgDppList = res?.data;
    if (this.bgDppList?.length >= res?.paginate?.totalCount) {
      this.getMoreBGDPP = false;
    }
  }

  async loadMoreBGDpp() {
    this.getBGDppList(true);
  }

  async getContents(query: any) {
    if (!query?.batchSubjectId) {
      this.globalService.showSnackBar('batchSubjectId is required.');
      return;
    }
    if (query.page === 1) {
      this.processing = true;
      this.quizContents.splice(0);
      this.getMoreDpps = true;
    }
    try {
      let list: BatchDppQuiz[] = [];
      const res: any = await lastValueFrom(
        await this.batchService.getDppQuiz(this.batchId, this.subjectId, query)
      );
      if (res && res['data']) {
        if (res['data'].length < 20) {
          this.getMoreDpps = false;
        }
        list = res['data'].map((item: any) => new BatchDppQuiz(item));
      } else {
        this.getMoreDpps = false;
      }
      this.quizContents = [...this.quizContents, ...list];
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }

  handleAttachmentClick() {
    this.firebaseService.logEvents(
      STUDY_PAGE_EVENT.ITEM_VIEW,
      {
        batch_selected: this.batchData.name,
        batch_id: this.batchData._id,
        cohort_id: this.userInfo['profileId']['cohortId'],
        item_section: 'dpp',
      },
      true,
      true,
      true,
      true
    );
  }

  async getTestStatus(test: TestList) {
    this.globalService.setTestBackUrl(this.router.url);
    if (!this.batchData.isPurchased) {
      const dialogRef = this.dialog.open(PurchaseAlertComponent, {
        data: {
          batchId: this.batchData.slug,
        },
      });
      return;
    }
    // if (test.isSubjective === true) {
    //   this.subjectiveTest(test);
    //   return;
    // }

    const query = {
      testListId: test._id,
    };
    if (test.totalQuestions === 0 && !test.isSubjective) {
      this.globalService.showSnackBar('Test is Empty !!');
      return;
    }
    try {
      const res = await lastValueFrom(this._testService.getTestStatus(query));
      if (res && test.isSubjective) {
        this.subjectiveTest(test, res);
        return;
      } else if (res && !test.isSubjective) {
        await this.testSeriesHelperService.checkTestSubmitStatus(
          test,
          res,
          this.batchData,
          'batches'
        );
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    }
  }

  getQueryParams = (batchScheduleId: any, url: string) => {
    let source = 'BATCH_TEST_SERIES';
    url += `?batchId=${this.batchData._id}&batchName=${this.batchData.name}&source=${source}&batchScheduleId=${batchScheduleId}&cameFrom=DPP`;
    return url;
  };

  async subjectiveDpp(dpp: BatchDppQuiz) {
    this.globalService.setDppBackUrl(this.router.url);
    this.globalService.setQBankReturnURL(this.router.url);
    if (!this.batchData.isPurchased && !dpp.isFree) {
      const dialogRef = this.dialog.open(PurchaseAlertComponent, {
        data: {
          batchId: this.batchData.slug,
        },
      });
      return;
    }
    this.setBatchIdSubjectId(dpp);

    if (this.isSubjective) {
      let list: BatchQuizModel[] = [];
      const response: any = await lastValueFrom(
        await this.batchService.getDppQuiz(
          this.batchId,
          this.subjectId,
          this.query
        )
      );
      if (response && response['data']) {
        list = response['data'].map((item: any) => new BatchDppQuiz(item));
        list.find((item: any) => {
          if (dpp.test.name === item.test.name) {
            this.batchScheduleId = item.scheduleId;
          }
        });
      }
      this.globalService.setTestBackUrl(this.router.url);

      let childUrl = this.getQueryParams(
        this.batchScheduleId,
        `/test/${dpp.test._id}/subjective-details`
      );

      this.router.navigate([`tests/${dpp.test._id}/new-test-result`], {
        queryParams: {
          childUrl,
        },
      });
    } else {
      // REDIRECTING TO NEW PRACTICE ENGINE
      this.globalService.setTestBackUrl(this.router.url);
      this.practiceService.startPractice({
        practiceData: dpp,
        fetchInstructions: false,
        practiceType: 'dpp',
        source: 'BATCH_QUIZ',
        scheduleId: dpp?.scheduleId || '',
        subjectName: this.subjectName,
        localstorageKeysToClear: [
          'testDetails',
          'testType',
          'questionTimer',
          'preDraft-meta',
        ],
        saarthi: {
          enabled: true,
          batchId: this.batchData._id,
          isPremium:
            this.batchData.template === 'MASTER' &&
            this.batchData.masterBatchId!.length > 0,
        },
        eventsData: {
          source: 'BATCH_SECTION',
        },
        batchId: this.batchData._id || '',
        contentId: dpp?.contentId || '',
      });
      // this.testSeriesHelperService.startTest(
      //   dpp,
      //   this.batchData,
      //   this.cameFrom,
      //   this.subjectName
      // );
    }
  }

  setBatchIdSubjectId(exercise?: any) {
    const data = {
      batchId: this.batchData._id,
      batchScheduleId: this.batchScheduleId || '',
      cameFrom: 'classroom',
    };
    this.storageService.setBatchIdSUbjectIdTest(data);
  }

  subjectiveTest(test: TestList, testStatus: any) {
    if (this.isSubjective) {
      const date = new Date();
      const startDate = new Date(test?.startTime);
      const maxStartTime = new Date(test?.maxStartTime);
      const endDate = new Date(test?.endTime);
      if (isAfter(date, endDate)) {
        this.globalService.showSnackBar('Test is Expired!!');
        return;
      }
      if (
        isBefore(date, endDate) &&
        isAfter(date, maxStartTime) &&
        testStatus?.testActivityStatus.toLowerCase() === 'notstarted'
      ) {
        this.globalService.showSnackBar('You are late for the test!!');
        return;
      }
      if (isBefore(date, startDate)) {
        this.globalService.showSnackBar('Test has not started yet');
        return;
      }
      if (!test.questionFileId) {
        this.globalService.showSnackBar('Test has not been uploaded yet"');
        return;
      }
      this.router.navigate(
        [`/batches/${this.batchData.slug}/batch-overview/tests/subjective`],
        {
          queryParams: {
            testId: test._id,
            testSlug: test.slug,
            title: test.name,
            type: this.type,
            cameFrom: 'dpp',
          },
        }
      );
    }
  }

  openContent(note: any) {
    this.openContentEvent.emit(note);
  }
  async getTestCategories() {
    if (this.testQuery.page === 1) {
      this.testProcessing = true;
      this.testPaginate = true;
      this.testListing.splice(0);
    }
    const list: Array<TestList> = [];
    try {
      const res = await lastValueFrom(
        this.batchService.getTestCategories(this.testQuery)
      );
      if (res && res.length > 0) {
        this.testPaginate = res.length >= 20;
        // this.testListing = res;
        res.forEach((res: any) => {
          list.push(res);
        });
        this.testListing = [...this.testListing, ...list];
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
      this.testProcessing = false;
      this.testListObservable = of(this.testList);
    }
  }

  loadMoreTest(event: boolean) {
    if (event && this.testPaginate) {
      this.testQuery.page++;
      this.getTestCategories();
    }
  }
  onScroll(event: any) {}

  loadMoreDpp() {}
}
