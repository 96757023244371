<section>
  <div class="pl-6 pr-3 py-2 flex justify-between items-center border-b">
    <span class="bold heading-4 sidebar-title">{{
      enableStudySource ? 'Syllabus & Study Sources' : 'Syllabus'
    }}</span>
    <div class="flex gap-4 items-center">
      <div
        class="relative inline-block text-left"
        *ngIf="
          instructionsData?.languageCodes &&
          instructionsData.languageCodes.length > 1
        "
      >
        <img
          (click)="toggleDropdown()"
          [src]="test_language"
          alt="syllabus-icon"
          class="cursor-pointer rounded-[4px] hover:bg-[#F8F8F8]"
        />

        <div
          *ngIf="showLangDropdown"
          class="language-option mt-4 py-2 body-1 medium origin-top-right absolute right-0 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none rounded-lg"
        >
          <div
            *ngFor="let lang of instructionsData?.languageCodes"
            [ngClass]="
              activeLang === lang.code
                ? 'active-lang-item bold-1'
                : 'hover:bg-gray-100'
            "
            class="block px-4 py-2 text-sm text-gray-700 lang-item cursor-pointer"
            (click)="handleLanguageChange(lang.code)"
          >
            {{ lang?.language }}
          </div>
        </div>
      </div>
      <img
        [src]="close_icon"
        alt="syllabus-icon"
        (click)="handleClose()"
        class="cursor-pointer rounded-[4px] hover:bg-[#F8F8F8]"
      />
    </div>
  </div>

  <!-- content -->
  <div class="px-6 pt-6 content-link">
    <app-mathjax
      [content]="
                instructionsData?.multiGeneralInstructions?.[
                 activeLang
                ]  || ''
              "
    ></app-mathjax>

    <div class="px-6 pt-6" *ngIf="showTestInstructions">
      <app-mathjax
        [content]="
                instructionsData?.multiTestInstructions?.[
                 activeLang
                ]  || ''
              "
      ></app-mathjax>
    </div>
  </div>
</section>
