export enum BATCH_CLUBBING_ENUM {
  BATCH_CLUBBING = 'batch_clubbing',
  BATCH_CLUBBING_URL = 'batch_clubbing_url',
  CLUBBING_UPSC_MENTORSHIP = 'clubbing_upsc_mentorship',
  NAVIGATION = 'navigation',
  BATCH_CARD = 'batch_card',
  KHAZANA_LANDING_PAGE = 'Khazana Landing Page',
  INFINITE_LEARNING = 'infinite_learning',
}

export enum BATCH_OFFERING_TYPE {
  PITARA = 'PITARA',
  INFINITE_MENTORSHIP = 'MENTORSHIP',
  INFINITE_PRACTICE = 'INFINITE_PRACTICE',
  KHAZANA = 'KHAZANA',
  SAARTHI = 'SAARTHI',
  BATCH = 'BATCH',
  UPSC_BANNER_MENTORSHIP = 'UPSC_BANNER_MENTORSHIP',
  TEST_CATEGORY_MODE = 'TEST_CATEGORY_MODE',
  TEST_PASS = 'TEST_PASS',
  OTHERS = 'OTHERS',
}

export enum BATCH_PLANS_TYPE {
  PREMIUM = 'PREMIUM',
}
